<template>
  <div id="content">
    <div class="wrapper">
      <header>
        <h1>{{ $t('message.fiducial_privacy_policy') }}</h1>
      </header>

      <div v-if="getLocale() === 'fr'">
        <h4>Préambule</h4>
        <p>Le Groupe FIDUCIAL est respectueux de la protection de la vie privée et des données à caractère
          personnel.</p>
        <p>La présente politique de confidentialité est destinée à fournir aux visiteurs et utilisateurs (ci-après
          désignés, ensemble ou individuellement, par «&nbsp;vous&nbsp;») de notre site https://www.mon-ecoffre.fr
          (ci-après désigné «&nbsp;le Site&nbsp;») des informations relatives à la collecte, à l’utilisation et au partage,
          par le Groupe FIDUCIAL (ci-après «&nbsp;nous&nbsp;»), de leurs données à caractère personnel.</p>
        <p>Elle décrit notamment la nature des données que nous collectons et les raisons pour lesquelles nous les
          collectons, les personnes auxquelles elles sont destinées, la durée pendant laquelle nous les
          conservons, ainsi que les droits dont vous pouvez vous prévaloir sur le traitement de vos données à
          caractère personnel et la façon de les mettre en œuvre, conformément aux nouvelles exigences introduites
          par le règlement (UE) 2016/679 du Parlement européen et du Conseil du 27 avril 2016 relatif à la
          protection des personnes physiques à l'égard du traitement des données à caractère personnel et à la
          libre circulation de ces données, entré en vigueur le 25 mai 2018 (ci-après désigné le
          «&nbsp;Règlement&nbsp;»).</p>
        <h4>1. Identité du responsable du traitement</h4>
        <p>Le responsable du traitement de vos données personnelles collectées sur le Site est&nbsp;:</p><p>

      </p><p>FIDUCIAL CLOUD, société par actions simplifiée, au capital social de 1.249.597,16 euros, dont le siège
        social est situé 41, rue du Capitaine Guynemer – 92400 COURBEVOIE, immatriculée au Registre du Commerce
        et des Sociétés de NANTERRE sous le numéro 480 650 712, et dont le numéro de TVA intracommunautaire est
        le FR06 480 650 712<br>
        Tél. : 04.72.20.76.00<br>
        Mél.&nbsp;: contact@fiducialcloud.fr</p>
        <h4>2. Identité du responsable du traitement</h4><br>
        <h5>2.1. Les données que vous nous transmettez</h5>
        <p>Les données que vous nous communiquez dans le cadre de votre utilisation du Site, et notamment, en
          remplissant nos formulaires, en vous inscrivant à notre newsletter, en participant à nos jeux concours
          ou enquêtes d’opinion, ou en nous contactant, sont les suivantes :</p>
        <ul>
          <li>
            les informations relatives à votre identité : nom, prénom&nbsp;;
          </li>
          <li>
            votre adresse de messagerie électronique.
          </li>
        </ul>
        <p>Les informations identifiées par un astérisque (*) sur le Site doivent obligatoirement être
          renseignées. À défaut, nous ne serons pas en mesure de vous fournir les services proposés sur notre
          Site.</p>
        <h5>2.2. Les données que nous recueillons automatiquement</h5>
        <p>Lors de chacune de vos visites sur notre Site, nous sommes susceptibles de recueillir, conformément à la
          législation applicable et avec votre accord, des informations relatives aux appareils sur lesquels vous
          utilisez nos services ou aux réseaux depuis lesquels vous accédez à nos services, telles que&nbsp;:</p>
        <ul>
          <li>
            des informations sur l’origine de vos visites (référencement naturel, e-mails, publicités, etc.)&nbsp;;
          </li>
          <li>
            des informations sur votre parcours de navigation sur notre Site, notamment votre parcours sur les
            différentes pages URL de notre Site, le contenu auquel vous accédez ou que vous consultez, les
            termes de recherches utilisés, la durée de consultation des pages, le nombre de vos clics, etc.&nbsp;;
          </li>
          <li>
            des informations vous concernant, notamment votre adresse IP, votre numéro de client, les types et
            versions de navigateurs Internet que vous utilisez, les résolutions de votre écran, les données de
            votre localisation géographique (afin d’optimiser le rendu de nos services et de déterminer les
            agences les plus adaptées à vos demandes).
          </li>
        </ul>
        <p>En cas d’accord préalable de votre part, des cookies sont susceptibles d’être déposés sur le disque dur
          de votre terminal (ordinateur, tablette, mobile, etc.).</p>
        <p>Pour plus d’information sur notre politique en matière de cookies, nous vous invitons à
          <a href="https://www.fiducial.fr/Cookie">cliquer ici.</a></p>

        <h4>3. Finalités et base juridique du traitement de vos données personnelles</h4>
        <p>Les données personnelles que nous collectons sont nécessaires pour vous fournir les services proposés sur
          notre Site.</p>
        <p>Vos données personnelles sont ainsi susceptibles d’être utilisées pour&nbsp;:</p>
        <ul>
          <li>
            vous envoyer des renseignements et des conseils sur nos offres de services, sur la base de votre
            consentement, et sur la base de notre intérêt légitime pour nous aider à mieux comprendre vos
            besoins et vos attentes et, ainsi, améliorer la qualité de nos services&nbsp;;
          </li>
          <li>
            vous permettre de participer à des jeux concours ou des enquêtes proposés sur le Site, sur la base
            de l’exécution d’un contrat auquel vous êtes partie&nbsp;;
          </li>
          <li>
            établir des statistiques anonymes relatives à l’utilisation du Site, sur la base de notre intérêt
            légitime pour nous aider à mieux comprendre vos besoins et vos attentes et, ainsi, améliorer la
            qualité de nos services&nbsp;;
          </li>
          <li>
            de manière générale, vous fournir tous les services mis à votre disposition sur notre Site.
          </li>
        </ul>
        <h4>4. Destinataires des informations que nous recueillons</h4>
        <p>Vos données personnelles sont susceptibles d’être communiquées aux sociétés du Groupe FIDUCIAL, à nos
          prestataires techniques et à nos partenaires afin de permettre leur traitement selon les finalités
          visées à l’article 3 ci-dessus, leur hébergement et, de manière générale, d’assurer le bon
          fonctionnement de nos services sur le Site.</p>
        <p>Au sein du Groupe FIDUCIAL, vos données personnelles sont accessibles aux personnes, en nombre limité,
          qui ont besoin d’en avoir connaissance dans le cadre de leur activité.</p>
        <h4>5. Durée de conservation des données recueillies</h4>
        <p>À défaut d’exercice des droits mentionnés à l’article 7 ci-dessous, nous conservons vos données
          personnelles pendant une durée n’excédant pas celle nécessaire au regard des finalités pour lesquelles
          elles sont traitées et mentionnées à l’article 3 ci-dessus.</p>
        <p>Les données personnelles sont conservées pour la durée la plus longue requise pour respecter les lois et
          réglementations en vigueur ou pour une autre durée liée à nos exigences opérationnelles, pour simplifier
          la gestion de la relation client et pour répondre aux actions en justice ou aux demandes réglementaires.
          Par exemple, la plupart des informations de nos clients sont conservées pour la durée de la relation
          contractuelle et, après la fin de la relation contractuelle, pour la durée nécessaire pour garantir
          l'exercice ou la défense d'actions en justice.</p>
        <p>Les informations recueillies sur notre Site via l’utilisation de cookies, et sous réserve de votre
          accord, sont conservées pour une durée n’excédant pas treize (13) mois, conformément à la réglementation
          en vigueur.</p>
        <h4>6. Protection de vos données</h4>
        <p>Nous mettons en œuvre des mesures de sécurité et de protection de vos données personnelles (mesures
          physiques, techniques et organisationnelles raisonnables et appropriées) pour prévenir et empêcher, dans
          la mesure du possible, toute altération ou perte de vos données ou tout accès non autorisé à
          celles-ci.</p>
        <p>Les mots de passe vous permettant de vous identifier en ligne sont cryptés.</p>
        <h4>7. Vos droits</h4>
        <h5>7.1. Informations sur vos droits</h5>
        <p>Conformément à la réglementation en vigueur, nous vous informons que vous disposez des droits suivants
          sur vos données à caractère personnel&nbsp;:</p>
        <ul>
          <li>
            un droit d’accès à vos données personnelles que nous collectons et traitons, et la possibilité d’en
            obtenir une copie&nbsp;;
          </li>
          <li>
            un droit de rectification des données vous concernant qui seraient inexactes et/ou incomplètes,
            ainsi qu’un droit d’effacement de celles-ci si vous estimez, notamment, qu’elles ne sont plus
            nécessaires au regard de la finalité du traitement ou qu’elles ont fait l’objet d’un traitement
            illicite&nbsp;;
          </li>
          <li>
            un droit de limitation du traitement de vos données personnelles notamment si vous contestez
            l’exactitude des données (après vérification par nos soins) ou encore si vous estimez que vos
            données ont fait l’objet d’un traitement illicite mais que vous ne souhaitez pas les supprimer&nbsp;;
          </li>
          <li>
            un droit à la portabilité de vos données qui vous permet de recevoir les données à caractère
            personnel vous concernant et que vous nous avez fournies, dans un format structuré, couramment
            utilisé et lisible par machine, et de les transmettre à un autre responsable de traitement&nbsp;;
          </li>
          <li>
            un droit de retirer votre consentement, à tout moment, lorsque le traitement des données à caractère
            personnel vous concernant est basé sur votre consentement, ce, sans porter atteinte à la licéité du
            traitement fondé sur votre consentement effectué avant le retrait de celui-ci&nbsp;;
          </li>
          <li>
            un droit de vous opposer, à tout moment, au traitement des données à caractère personnel vous
            concernant basé sur notre intérêt légitime ainsi qu’à des fins de prospection commerciale.
          </li>
        </ul>
        <h5>7.2. Exercice de vos droits</h5>
        <p>Les droits mentionnés à l’article 7.1. susvisé peuvent être exercés à tout moment&nbsp;en adressant un
          courrier à l’adresse suivante&nbsp;:</p>
        <p>FIDUCIAL CLOUD<br>
          À l’attention du DPO<br>
          19 rue Cottin - 69009 LYON</p>

        <p>Toute demande relative à l’exercice de vos droits devra être accompagnée d’une copie signée d’un titre
          d’identité.</p>
        <p>L’exercice de vos droits sur vos données à caractère personnel se fait sans frais. Toutefois, en cas de
          demandes manifestement infondées ou excessives, notamment en raison de leur caractère répétitif, nous
          pourrons exiger le paiement de frais raisonnables tenant compte des coûts administratifs supportés pour
          fournir les informations, procéder aux communications ou prendre les mesures demandées, ou bien refuser
          de donner suite à ces demandes.</p>
        <h5>7.3. Délai de réponse</h5>
        <p>Nous nous engageons à vous fournir des informations sur les mesures prises à la suite d’une demande
          d’exercice de vos droits, dans les meilleurs délais et en tout état de cause dans les délais légaux.</p>
        <h5>7.4. Réclamation auprès de l’autorité compétente</h5>
        <p>Vous disposez du droit d’introduire une réclamation auprès de l’autorité de contrôle compétente si vous
          estimez que le traitement de vos données à caractère personnel constitue une violation des lois et
          règlements en vigueur sur la protection des données à caractère personnel.</p>
        <p>Pour la France, l’autorité de contrôle compétente est la Commission Nationale de l’Informatique et des
          Libertés (CNIL).</p>
        <h4>8. Modification de notre politique de confidentialité</h4>
        <p>Nous pouvons être amenés à modifier la présente politique de confidentialité, notamment, pour des raisons
          de mise en conformité aux lois et règlements en vigueur.</p>
        <p>Nous vous conseillons de consulter régulièrement cette page pour prendre connaissance de ces éventuelles
          modifications ou mises à jour.</p>
      </div>

      <div v-if="getLocale() === 'en'">
        <h4>Foreword</h4>
        <p>The Group FIDUCIAL respects the protection of privacy and of personal data.</p>
        <p>
          This Privacy Policy is intended to provide visitors and users (hereinafter referred to, together or
          individually, as “you”) of our website https://www.mon-ecoffre.fr (hereinafter referred to as “the Site”)
          with information relating to the collection, the use and sharing of their personal data by the Group
          FIDUCIAL (hereinafter “we”).
        </p>
        <p>
          It describes in particular the nature of the data we collect and the reasons why we collect them, the persons
          for whom they are intended, the duration for which we keep them, as well as the rights you have regarding
          the processing of your personal data and how to enforce these rights, in accordance with the new requirements
          introduced by Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on
          the protection of natural persons with regard to the processing of personal data and on the free movement
          of such data, entered into force on 25 May 2018 (hereinafter referred to as the “Regulation”).
        </p>

        <h4>1. Identity of the controller</h4>
        <p>The controller of the processing of your personal data collected on the Site is:</p>
        <br><br>
        <p>
          FIDUCIAL Cloud, Société par Actions Simplifiée, with a share capital of 1.249.597,16 euros, having its
          registered office at 41, rue du Capitaine Guynemer – FR-92400 COURBEVOIE, France, registered in the
          NANTERRE (France) Trade and Companies Register under number 480 650 712 and with intra-community VAT
          number FR06 480 650 712.<br>
          Tel.: +33 (0)4.72.20.76.00<br>
          Email: contact@fiducialcloud.fr
        </p>

        <h4>2. Data processed</h4>
        <h5>2.1. Data you provide to us</h5>
        <p>
          The data that you provide to us in the context of your use of the Site, and in particular, by completing our
          forms, subscribing to our newsletter, participating in our contests or opinion surveys, or contacting us,
          are the following:
        </p>
        <ul>
          <li>information about your identity: last name, first name;</li>
          <li>your e-mail address.</li>
        </ul>
        <p>
          The information identified by an asterisk (*) on the Site is mandatory. Without these data, we will not be
          able to provide you with the services offered on our Site.
        </p>

        <h5>2.2. Data that we collect automatically</h5>
        <p>
          Each time that you visit our Site, we may collect, in accordance with the applicable law and with your
          consent, information about the devices on which you use our services or the networks from which you access
          our services, such as:
        </p>
        <ul>
          <li>Information on the origin of your visits (natural referencing, e-mails, advertisements, etc.);</li>
          <li>Information about your click stream data on our Site, including your browsing experience on the various
            URL pages of our Site, the content you access or consult, the search terms used, the duration of page
            views, the number of clicks, etc.;</li>
          <li>Information about you, such as your IP address, your client number, types and versions of Internet
            browsers you use, your screen resolutions and your geographic location data (in order to optimize our
            services and to determine the most suitable offices for your needs).</li>
        </ul>
        <p>
          With your prior consent, cookies may be stored on the hard disk of your terminal (computer, tablet, mobile, etc.).
        </p>
        <p>
          For further information on our cookie policy, you can <a href="https://www.fiducial.fr/Cookie">click here.</a>
        </p>

        <h4>3. Purposes and legal basis for the processing of your personal data</h4>
        <p>The personal data we collect are necessary to provide you with the services offered on our Site.</p>
        <p>Your personal data may thus be used to:</p>
        <ul>
          <li>
            send you information and advice about our services, based on your consent and on our legitimate interest
            to help us better understand your needs and expectations and thus improve the quality of our services;
          </li>
          <li>
            allow you to participate in contests or surveys offered on the Site, based on the performance of a
            contract to which you are a party;
            compile anonymous statistics relating to the use of the Site, based on our legitimate interest to help
            us better understand your needs and expectations and thus improve the quality of our services;
          </li>
          <li>
            in general, provide you with all the services made available to you on our Site.
          </li>
        </ul>

        <h4>4. Recipients of the data we collect</h4>
        <p>
          Your personal data may be communicated to the companies of the FIDUCIAL Group, to our technical service
          providers and our partners in order to enable their processing for the purposes referred to in article 3
          above, their hosting and, in general, to ensure the correct functioning of our services on the Site.
        </p>
        <p>
          Within the FIDUCIAL Group, your personal data are accessible to persons, in a limited number, who need
          to know them in the context of their activity.
        </p>

        <h4>5. Duration of collected data retention</h4>
        <p>If you do not exercise the rights mentioned in article 7 below, we store your personal data for a period not
          exceeding the duration necessary for the purposes for which they are processed and mentioned in article 3 above.</p>
        <p>Personal data are stored for the longest period required to comply with applicable laws and regulations or
          for another duration in relation with our operational requirements, to simplify client relationship management
          and to respond to legal actions or regulatory requests. For example, most of our clients’ information is
          retained for the duration of the contractual relationship and, after the end of the contractual relationship,
          for as long as necessary to guarantee the exercise or defense of legal actions.</p>
        <p>The information collected on our Site through the use of cookies, and subject to your consent, is kept for a
          duration not exceeding thirteen (13) months, in accordance with the regulations in force.</p>

        <h4>6. Protection of your data</h4>
        <p>
          We implement security and protection measures for your personal data (reasonable and appropriate physical,
          technical and organizational measures) to prevent and avoid, as far as possible, any alteration, loss or
          unauthorized access to your data.
        </p>
        <p>
          Passwords that allow you to log in online are encrypted.
        </p>

        <h4>7. Your rights</h4>
        <h5>7.1. Information on your rights</h5>
        <p>In accordance with the regulations in force, we inform you that you have the following rights with regard
          to your personal data:</p>
        <ul>
          <li>
            a right of access to your personal data that we collect and process, and the possibility to obtain a
            copy of it;</li>
          <li>
            a right to rectification of the data concerning you that would be inaccurate and/or incomplete, as well
            as a right to erasure if you consider, in particular, that your personal data are no longer necessary in
            relation to the purposes for which they were processed or that they have been unlawfully processed; </li>
          <li>
            a right to restriction of processing of your personal data, in particular if you contest the accuracy
            of the data (after verification by us) or if you believe that your data have been unlawfully processed
            but you do not wish to delete them;
            a right to data portability which allows you to receive your personal data that you have provided to us,
            in a structured, commonly used and machine-readable format, and to transmit them to another data controller;
          </li>
          <li>a right to withdraw your consent, at any time, where the processing of your personal data is based
            on your consent, this without prejudice to the lawfulness of the processing based on your consent
            given before its withdrawal; </li>
          <li>a right to object, at any time, to the processing of personal data concerning you based on our
            legitimate interest as well as for commercial prospecting purposes.</li>
        </ul>
        <h5>7.2. Exercise of your rights</h5>
        <p>
          The rights mentioned in article 7.1. above may be exercised at any time by sending a letter to the following
          address:
        </p>
        <p>
          FIDUCIAL CLOUD<br>
          For the attention of the DPO<br>
          19 rue Cottin - 69009 LYON
        </p>
        <p>
          Any request relating to the exercise of your rights shall be accompanied by a signed copy of an identity document.
        </p>
        <p>
          The exercise of your rights over your personal data is free of charge. However, in the event of manifestly
          unfounded or excessive requests, in particular because of their repetitive nature, we may require payment
          of reasonable fees taking into account the administrative costs incurred in providing the information,
          communicating it or taking the requested measures, or we may refuse to process these requests.
        </p>
        <h5>7.3. Response time</h5>
        <p>
          We commit to provide you with information on the measures taken following a request to exercise your rights,
          as soon as possible and in any case within the legal deadlines.
        </p>
        <h5>7.4. Complaint before the competent authority</h5>
        <p>
          You have the right to submit a complaint with the competent supervisory authority if you consider that the
          processing of your personal data constitutes a breach of the laws and regulations in force on the protection
          of personal data.
        </p>
        <p>
          For France, the competent supervisory authority is the “Commission Nationale de l’Informatique et des Libertés” (CNIL).
        </p>

        <h4>8. Changes to our Privacy Policy</h4>
        <p>
          We may change this Privacy Policy, for instance to ensure compliance with applicable laws and regulations.
        </p>
        <p>
          We recommend that you check this page regularly in order to be informed of these possible changes or updates.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import DefaultLayout from '../../layouts/DefaultLayout.vue'
import config from '../../config'

export default {
  name: 'PrivacyPolicy',
  created () {
    this.$emit('update:layout', DefaultLayout)
    document.title = config.title + ' - Politique de confidentialité'
  },
  methods: {
    getLocale () {
      return localStorage.getItem('locale') || 'fr'
    }
  }
}
</script>
